import React from 'react'
import { EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline';
import { dateToHumanFormat } from '../../../utils/constants';

function Item({ item }) {

    function formatArray(arr) {
        return arr.join(', ');
    }

    return (
        <>
        <tr className="px-4 py-6 border-b hover:bg-gray-100 text-sm">
            <td className='px-4 py-3 text-ellipsis overflow-hidden flex items-center'>
                {item?.name}
            </td>
            <td className='px-4 py-3 text-ellipsis overflow-hidden'>
                {item?.owners}
            </td>
            <td className='px-4 py-3'>
                {formatArray(item?.phones)}
            </td>
            <td className='px-4 py-3'>
                {dateToHumanFormat(item?.establishDate)}
            </td>
            <td className='px-4 py-3 inline-flex items-center space-x-4'>
                {/* <Link onClick={event => passUserId(event, item?._id || item?._id)} to="/admin/clients/edit" className='cursor-pointer hover:text-gold-500'>
                    View
                </Link>

                <ClientToggle 
                    _id={item?._id}
                    status={item?.isDisabled}
                    setClientList={setClientList}
                /> */}
                <EllipsisHorizontalCircleIcon 
                    className="w-6 h-6 hover:scale-125 transition-all duration-75 ease-in-out hover:cursor-pointer" 
                />
            </td>
        </tr>
        </>
    )
}

export default Item