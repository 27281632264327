import { processingfees, perMonthPaymentFractions, interestRate as rate } from "./loanConstants";
import { commissionCalc, roundNum } from "./financials";

async function getLoanMePaymentValues(loanAmt, body, monthInterestRate) {
    let payFreq = body.paymentPlan;
    let loanPayMonths = body.paymentDuration;
    let processingFee = roundNum((processingfees.loanme / 100) * loanAmt, 2);
    let insuranceFee = roundNum((processingfees.lifeInsurance / 100) * loanAmt, 2);
    let amountGiven = roundNum(loanAmt - processingFee - insuranceFee, 2);

    //let unitPayFraction = personalUnitPaymentFractions[payFreq];
    let perMonthPayFract = perMonthPaymentFractions[payFreq];
    if (!perMonthPayFract) throw new Error(`unknown payment frequency : ${payFreq}`);
    let numPayments = loanPayMonths / perMonthPayFract;
    let unitPayFraction = perMonthPayFract / loanPayMonths;
    let interestRate = perMonthPayFract * monthInterestRate; // unit pay (e.g weekly) interest rate

    // let totalPayment = parseFloat((loanAmt*(1 +monthInterestRate/100)).toFixed(2));
    let totalPayment = roundNum((loanAmt * (1 + (numPayments * interestRate) / 100)), 2);
    let premium = roundNum((unitPayFraction * totalPayment), 2);
    let lastPayment = roundNum((totalPayment - (numPayments - 1) * premium), 2);
    let sumInterest = roundNum(((interestRate / 100) * loanAmt * numPayments), 2);

    console.log(`amountGiven: ${amountGiven}, interestRate: ${interestRate}, loan premium: ${premium}, 
    numPayments: ${numPayments}, totalPayment: ${totalPayment}, last payment: ${lastPayment}`);

    return {
        amountGiven, premium, interestRate, numPayments, totalPayment, lastPayment,
        sumInterest, processingFee, insuranceFee, faciLetterProcessingFee: processingfees.loanme, faciLetterInsuranceFee: processingfees.lifeInsurance
    };
}


// process simple score pension loan payment schedules etc. totalPayment, numPayments
async function getPensionPaymentValues(loanAmt, body, monthInterestRate) {

    let petraInterestPM, petraCommPM, petraCommTotal;
    if(body.loanKind === "Axis" || body.loanKind === "Metropolitan") {
        petraInterestPM = 0;
        petraCommPM = 0;
        petraCommTotal = 0;
    } else {
        const { interest, perMonth, total } = commissionCalc(4, body.paymentDuration, loanAmt);
        petraInterestPM = interest;
        petraCommPM = perMonth;
        petraCommTotal = total;
    }

    let payFreq = body.paymentPlan;
    let loanPayMonths = body.paymentDuration;
    let processingFee = roundNum((processingfees.pension / 100) * loanAmt, 2);
    let axisProcessingFee = body.loanKind === "Axis" ? roundNum((1.5 / 100) * loanAmt, 2) : 0;
    let metroProcessingFee = body.loanKind === "Metropolitan" ? roundNum((1.5 / 100) * loanAmt, 2) : 0;
    let insuranceFee;
    // let insuranceFee = roundNum((lifeInsurancePercent/100)*loanAmt, 2);
    if(parseFloat(body.pensionAsset) > loanAmt){
        insuranceFee = roundNum(0 * loanAmt, 2)
    } else {
        insuranceFee = roundNum((processingfees.lifeInsurance / 100) * loanAmt, 2)
    }

    // let amountGiven = roundNum(loanAmt - processingFee - insuranceFee, 2);
    let amountGiven = roundNum(loanAmt - processingFee - insuranceFee, 2);

    //let unitPayFraction = personalUnitPaymentFractions[payFreq];
    let perMonthPayFract = perMonthPaymentFractions[payFreq];
    if (!perMonthPayFract) throw new Error(`unknown payment frequency : ${payFreq}`);
    let numPayments = loanPayMonths / perMonthPayFract;
    let unitPayFraction = perMonthPayFract / loanPayMonths;
    let interestRate = perMonthPayFract * (petraInterestPM + monthInterestRate); // unit pay (e.g weekly) interest rate

    // let totalPayment = parseFloat((loanAmt*(1 +monthInterestRate/100)).toFixed(2));
    let totalPayment = roundNum((loanAmt * (1 + (numPayments * interestRate) / 100)), 2) + petraCommTotal;
    let premium = roundNum((unitPayFraction * totalPayment), 2);
    let lastPayment = roundNum((totalPayment - (numPayments - 1) * premium), 2);
    let sumInterest = roundNum(((interestRate / 100) * loanAmt * numPayments), 2);

    console.log(`amountGiven: ${amountGiven}, interestRate: ${interestRate}, loan premium: ${premium}, 
    numPayments: ${numPayments}, totalPayment: ${totalPayment}, last payment: ${lastPayment}, processing fee: ${processingFee}, ${body.loanKind === "Metropolitan" ? "metro processing fee: " + metroProcessingFee : ""}`);
    
    return {
        amountGiven, premium, interestRate, numPayments, totalPayment, lastPayment, sumInterest, processingFee, metroProcessingFee, axisProcessingFee, insuranceFee, faciLetterProcessingFee: processingfees.pension, faciLetterInsuranceFee: (parseFloat(body.pensionAsset) > loanAmt) ? 0 : processingfees.lifeInsurance, petraCommTotal, petraCommPM, petraInterestPM
    };
}

// CapitalMe Payment Values
async function getCapitalMePaymentValues(loanAmt, body, monthInterestRate) {
    let payFreq = body.paymentPlan;
    let loanPayMonths = body.paymentDuration;
    let processingFee = roundNum((processingfees.capitalme / 100) * loanAmt, 2);
    let insuranceFee = roundNum((processingfees.lifeInsurance / 100) * loanAmt, 2);
    let amountGiven = roundNum(loanAmt - processingFee - insuranceFee, 2);

    let perMonthPayFract = perMonthPaymentFractions[payFreq];
    if (!perMonthPayFract) throw new Error(`unknown payment frequency : ${payFreq}`);
    let numPayments = loanPayMonths / perMonthPayFract;
    let interestRate = perMonthPayFract * monthInterestRate;
    let unitPayFraction = perMonthPayFract / loanPayMonths;

    let totalPayment = roundNum((loanAmt * (1 + (numPayments * interestRate) / 100)), 2);
    let premium = roundNum((unitPayFraction * totalPayment), 2);
    let lastPayment = roundNum((totalPayment - (numPayments - 1) * premium), 2);
    let sumInterest = roundNum(((interestRate / 100) * loanAmt * numPayments), 2);

    console.log(`amountGiven: ${amountGiven}, interestRate: ${interestRate}, premium: ${premium},
    numPayments: ${numPayments}, totalPayment: ${totalPayment}, lastPayment: ${lastPayment}`);

    return {
        amountGiven, premium, interestRate, numPayments, totalPayment, lastPayment,
        sumInterest, processingFee, insuranceFee, faciLetterProcessingFee: processingfees.capitalme, 
        faciLetterInsuranceFee: processingfees.lifeInsurance
    };
}


async function getCreditPaymentValues(loanAmt, body, monthInterestRate) {
    let payFreq = body.paymentPlan;
    let loanPayMonths = body.paymentDuration;
    let processingFee = 0;
    let insuranceFee = 0;
    let amountGiven = roundNum(loanAmt - processingFee - insuranceFee, 2);

    let perMonthPayFract = perMonthPaymentFractions[payFreq];
    if (!perMonthPayFract) throw new Error(`unknown payment frequency : ${payFreq}`);
    let numPayments = loanPayMonths / perMonthPayFract;
    let interestRate = roundNum(perMonthPayFract * monthInterestRate, 2);
    let unitPayFraction = perMonthPayFract / loanPayMonths;

    let totalPayment = roundNum((loanAmt * (1 + (numPayments * interestRate)/100)), 2);
    let premium = roundNum((unitPayFraction * totalPayment), 2);
    let lastPayment = roundNum((totalPayment -(numPayments - 1) * premium), 2);
    let sumInterest = roundNum(((interestRate / 100) * loanAmt * numPayments), 2);

    console.log(`amountGiven: ${amountGiven}, interestRate: ${interestRate}, premium: ${premium},
    numPayments: ${numPayments}, totalPayment: ${totalPayment}, lastPayment: ${lastPayment}`);

    return {
        amountGiven, premium, interestRate, numPayments, totalPayment, lastPayment,
        sumInterest, processingFee, insuranceFee
    };
}

async function getCashMePaymentValues(loanAmt, body, monthInterestRate){
    let payFreq = body.paymentPlan;
    let loanPayMonths = body.paymentDuration;
    let processingFee = 0;
    let insuranceFee = roundNum((processingfees.lifeInsurance / 100) * loanAmt, 2);
    let amountGiven = roundNum(loanAmt - processingFee - insuranceFee, 2);

    let perMonthPayFract = perMonthPaymentFractions[payFreq];
    if (!perMonthPayFract) throw new Error(`unknown payment frequency : ${payFreq}`);
    let numPayments = loanPayMonths / perMonthPayFract;
    let interestRate = perMonthPayFract * monthInterestRate;
    let unitPayFraction = perMonthPayFract / loanPayMonths;

    let totalPayment = roundNum((loanAmt * (1 + (numPayments * interestRate) / 100)), 2);
    let premium = roundNum((unitPayFraction * totalPayment), 2);
    let lastPayment = roundNum((totalPayment - (numPayments - 1) * premium), 2);
    let sumInterest = roundNum(((interestRate / 100) * loanAmt * numPayments), 2);

    console.log(`amountGiven: ${amountGiven}, interestRate: ${interestRate}, premium: ${premium},
    numPayments: ${numPayments}, totalPayment: ${totalPayment}, lastPayment: ${lastPayment}`);

    return {
        amountGiven, premium, interestRate, numPayments, totalPayment, lastPayment,
        sumInterest, processingFee, insuranceFee
    };
}

async function getInvestmentPaymentValues(loanAmt, body, monthInterestRate) {
    // TODO: add achieve's 5% commission on loan (entire period) to premium
    let achieveCommTotal = roundNum((((rate.achieveCap * body.paymentDuration) / 100) * loanAmt), 2);
    let achieveCommPM = roundNum((((rate.achieveCap * body.paymentDuration) / 100) * loanAmt) / body.paymentDuration, 2);
    console.log("achieve comm total", achieveCommTotal)
    
    // TODO: add petra's 5% commission on loan (entire period) to premium
    let petraCommTotal = roundNum(((5 / 100) * loanAmt), 2);
    let petraCommPM = roundNum(((5 / 100) * loanAmt) / body.paymentDuration, 2);
    console.log("petra comm total", petraCommTotal)

    let payFreq = body.paymentPlan;
    let loanPayMonths = body.paymentDuration;
    let processingFee = body.loanKind === "achieve" ? 
        roundNum((processingfees.investmentAchieve / 100) * loanAmt, 2) : 
        roundNum((processingfees.investmentPetra / 100) * loanAmt, 2);
    let insuranceFee = 0;
    let amountGiven = roundNum(loanAmt - processingFee - insuranceFee, 2);

    //let unitPayFraction = personalUnitPaymentFractions[payFreq];
    let perMonthPayFract = perMonthPaymentFractions[payFreq];
    if (!perMonthPayFract) throw new Error(`unknown payment frequency : ${payFreq}`);
    let numPayments = loanPayMonths / perMonthPayFract;
    let unitPayFraction = perMonthPayFract / loanPayMonths;
    let interestRate = perMonthPayFract * monthInterestRate; // unit pay (e.g weekly) interest rate
    
    let totalPayment = body.loanKind === "achieve" ? 
        roundNum((loanAmt * (1 + (numPayments * interestRate) / 100)), 2) + achieveCommTotal : 
        roundNum((loanAmt * (1 + (numPayments * interestRate) / 100)), 2);

    let premium = roundNum((unitPayFraction * totalPayment), 2);
    let lastPayment = roundNum((totalPayment - (numPayments - 1) * premium), 2);
    let sumInterest = roundNum(((interestRate / 100) * loanAmt * numPayments), 2);

    console.log(`amountGiven: ${amountGiven}, interestRate: ${interestRate}, loan premium: ${premium}, 
    numPayments: ${numPayments}, totalPayment: ${totalPayment}, last payment: ${lastPayment}`);

    return { amountGiven, premium, interestRate, numPayments, totalPayment, lastPayment, sumInterest, processingFee, insuranceFee, faciLetterProcessingFee: processingFee, faciLetterInsuranceFee: insuranceFee, partnerCommTotal: body.loanKind === "achieve" ? achieveCommTotal : petraCommTotal, partnerCommPM: body.loanKind === "achieve" ? achieveCommPM : petraCommPM };
}


async function getPayrollPaymentValues(loanAmt, body, monthInterestRate) {

    // TODO: add achieve's 5% and petra's 4% commission on loan (entire period) to premium
    let achieveInterestPM = 0, achieveCommPM = 0, achieveCommTotal = 0;
    if(body.loanKind === "Achieve Customer" || body.loanKind === "Promo Employer") {
        const { interest, perMonth, total } = commissionCalc(5, body.paymentDuration, loanAmt);
        achieveInterestPM = interest;
        achieveCommPM = perMonth;
        achieveCommTotal = total;
    }
    
    if(body.loanKind === "Employer-Petra") {
        const { interest, perMonth, total } = commissionCalc(4, body.paymentDuration, loanAmt);
        achieveInterestPM = interest;
        achieveCommPM = perMonth;
        achieveCommTotal = total;
    }

    let payFreq = body.paymentPlan;
    let loanPayMonths = body.paymentDuration;
    let processingFee = roundNum((processingfees.employer / 100) * loanAmt, 2);
    let insuranceFee = roundNum((processingfees.lifeInsurance / 100) * loanAmt, 2);
    let amountGiven = roundNum(loanAmt - processingFee - insuranceFee, 2);

    //let unitPayFraction = personalUnitPaymentFractions[payFreq];
    let perMonthPayFract = perMonthPaymentFractions[payFreq];
    if (!perMonthPayFract) throw new Error(`unknown payment frequency : ${payFreq}`);
    let numPayments = loanPayMonths / perMonthPayFract;
    let unitPayFraction = perMonthPayFract / loanPayMonths;
    let interestRate = perMonthPayFract * (body.loanKind === "Achieve Customer" || body.loanKind === "Employer-Petra") ? roundNum((monthInterestRate + achieveInterestPM), 2) : monthInterestRate; // unit pay (e.g weekly) interest rate
    
    // let totalPayment = roundNum(roundNum((loanAmt * (1 + (numPayments * interestRate) / 100)), 2) + achieveCommTotal, 2);
    let totalPayment = (body.loanKind === "Achieve Customer" || body.loanKind === "Employer-Petra" || body.loanKind === "Promo Employer") ? 
            roundNum((loanAmt * (1 + (numPayments * interestRate) / 100)), 2) + achieveCommTotal : 
            roundNum((loanAmt * (1 + (numPayments * interestRate) / 100)), 2);

    let premium = roundNum((unitPayFraction * totalPayment), 2);
    let lastPayment = roundNum((totalPayment - (numPayments - 1) * premium), 2);
    let sumInterest = roundNum(((interestRate / 100) * loanAmt * numPayments), 2);
    console.log(`amountGiven: ${amountGiven}, interestRate: ${interestRate}, loan premium: ${premium}, 
    numPayments: ${numPayments}, totalPayment: ${totalPayment}, last payment: ${lastPayment}, sumInterest: ${sumInterest}, processingFee: ${processingFee}, insuranceFee: ${insuranceFee}, achieveCommTotal: ${achieveCommTotal}, achieveCommPM: ${achieveCommPM}`);

    return {
        amountGiven, premium, interestRate, numPayments, totalPayment, lastPayment, sumInterest, processingFee, insuranceFee, faciLetterProcessingFee: processingFee, faciLetterInsuranceFee: insuranceFee, achieveCommTotal: (body.loanKind === "Achieve Customer" || body.loanKind === "Employer-Petra") ? achieveCommTotal : 0, achieveCommPM: (body.loanKind === "Achieve Customer" || body.loanKind === "Employer-Petra") ? achieveCommPM : 0,
    };

}

export {
    getLoanMePaymentValues,
    getPensionPaymentValues,
    getCapitalMePaymentValues,
    getCreditPaymentValues,
    getCashMePaymentValues,
    getInvestmentPaymentValues,
    getPayrollPaymentValues
}