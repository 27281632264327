import { BusinessActs } from "../../../components";
import { dateToHumanFormat, loanTypes } from "../../../utils/constants";
import { workYearsReverse } from "../../../utils/loanConstants";

function Info({ loan }) {

    return (
        <>
        <tr colSpan="2" className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td colSpan="2" className='px-4 py-3 font-bold'>Personal / Business Information</td>
        </tr>
        {loan?.client?.failedVerification && (
        <tr colSpan={2} className="px-4 py-6 border-b bg-red-50 text-red-700">
            <td colSpan={2} className='px-4 py-3'>
                Failed NIA ID Verification, please verify the client's information
            </td>
        </tr>
        )}
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Name</td>
            <td className='px-4 py-3'>
                {loan?.client?.otherNames} {loan?.client?.surname}
            </td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Phone</td>
            <td className='px-4 py-3'>
                {loan?.client?.phone}
            </td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Email Address</td>
            <td className='px-4 py-3'>{loan?.client?.email}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Digital Address</td>
            <td className='px-4 py-3'>{loan?.client?.digitalAddress}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Residential Address</td>
            <td className='px-4 py-3'>{loan?.client?.address}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Signed Name on Loan</td>
            <td className='px-4 py-3'>
                {loan?.client?.otherNames} {loan?.client?.surname}
            </td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Employer's Name</td>
            <td className='px-4 py-3'>{loan?.employerName}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Employment Status</td>
            <td className='px-4 py-3 capitalize'>{loan?.employedCurrently}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Number of years at work</td>
            <td className='px-4 py-3 capitalize'>{workYearsReverse[loan?.yearsAtWork]}</td>
        </tr>

        {/* business activites section */}
        {(loan?.loanType === loanTypes?.capitalme || loan?.loanType === loanTypes?.cashme) && 
            <BusinessActs 
                business={loan?.business[0]}
                loan={loan}
            />
        }

        
        {/* credit payment information */}
        <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
            <td className='px-4 py-3'></td>
            <td className='px-4 py-3'></td>
        </tr>
        <tr colSpan="2" className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td colSpan="2" className='px-4 py-3 font-bold'>Credit Payment Information</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Credit Score</td>
            <td className='px-4 py-3'>{loan?.score}%</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Debt to Income Ratio</td>
            <td className='px-4 py-3'>{loan?.DIR}%</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Willingness conclusion</td>
            <td className='px-4 py-3 capitalize'>{loan?.willingness}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Capacity conclusion</td>
            <td className='px-4 py-3 capitalize'>{loan?.capacity}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Preliminary credit conclusion</td>
            <td className='px-4 py-3 capitalize'>
                {loan?.creditConc}
            </td>
        </tr>


        {/* Income information details */}
        <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
            <td className='px-4 py-3'></td>
            <td className='px-4 py-3'></td>
        </tr>
        <tr colSpan="2" className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td colSpan="2" className='px-4 py-3 font-bold'>Income Information Details</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Income or Revenue</td>
            <td className='px-4 py-3'>GHC {loan?.income}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Frequency of Income</td>
            <td className='px-4 py-3 capitalize'>{loan?.freqIncome}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Debt Payments</td>
            <td className='px-4 py-3'>{loan?.debtAmt}</td>
        </tr>
        {/* <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Monthly Living Expenses</td>
            <td className='px-4 py-3'>{loan?.loanPreApprovedDecision?.expenses}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Monthly Savings</td>
            <td className='px-4 py-3'>{loan?.currentSavingsBalance}</td>
        </tr> */}
       <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Savings Habit</td>
            <td className='px-4 py-3 capitalize'>
                {loan?.savingHabits === "Yes" ? `${loan?.savingHabits} - [M1 - ${loan?.savingsFreq1}] - [M2 - ${loan?.savingsFreq2}] - [M3 - ${loan?.savingsFreq3}]` : loan?.savingHabits}
            </td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Insurance Habit / Type</td>
            <td className='px-4 py-3 capitalize'>
                {loan?.typeOfInsurance !== "None" ? "Yes" : "None"} / {loan?.typeOfInsurance}
            </td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Are your loan payments automated</td>
            <td className='px-4 py-3 capitalize'>{loan?.automatedPaymentsStatus}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>System you currently use to automate your loan payments</td>
            <td className='px-4 py-3 capitalize'>{loan?.autoDeduction}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Recent 3 months bills payment</td>
            <td className='px-4 py-3 capitalize'>{loan?.billPaymentHistory}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Recent 3 months loan payment</td>
            <td className='px-4 py-3 capitalize'>{loan?.loanPaymentHistory}</td>
        </tr>

        
        {/* loan details information */}
        <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
            <td className='px-4 py-3'></td>
            <td className='px-4 py-3'></td>
        </tr>
        <tr colSpan="2" className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td colSpan="2" className='px-4 py-3 font-bold'>Loan Details Information</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Loan Amount</td>
            <td className='px-4 py-3'>{loan?.amount}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Amount Requested</td>
            <td className='px-4 py-3'>{loan?.amountReq}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Eligible Amount</td>
            <td className='px-4 py-3'>{loan?.amountElig}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Number of Months</td>
            <td className='px-4 py-3'>{loan?.paymentDuration}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Payment Plan</td>
            <td className='px-4 py-3'>{loan?.paymentPlan}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Repayment Method</td>
            <td className='px-4 py-3'>{loan?.repaySource}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Taken a loan from other finacial institution</td>
            <td className='px-4 py-3 capitalize'>
                {loan?.loansTaken}
            </td>
        </tr>
        
        {/* {(loan?.type === loanTypes.cashme || loan?.type === loanTypes.capitalme) &&
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Length of loan borrowing or payment history</td>
            <td className='px-4 py-3'>
                {loan?.custRelTime}
            </td>
        </tr>
        } */}


        {/* credit bureau consent information */}
        
        {(loan.loanType === loanTypes.cashme || loan.loanType === loanTypes.capitalme) &&
        <>
        <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
            <td className='px-4 py-3'></td>
            <td className='px-4 py-3'></td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>How long one has held their mobile money account</td>
            <td className='px-4 py-3'>{loan?.momoNumberDuration}</td>
        </tr>
        </>
        }

        {/* guarantor information */}
        <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
            <td className='px-4 py-3'></td>
            <td className='px-4 py-3'></td>
        </tr>
        <tr colSpan="2" className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td colSpan="2" className='px-4 py-3 font-bold'>Guarantor's Information</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Guarantor's Name</td>
            <td className='px-4 py-3'>{loan?.guarantorFirstName} {loan?.guarantorLastName}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Guarantor's phone</td>
            <td className='px-4 py-3'>{loan?.guarantorPhone}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Guarantor's ID Type and Number</td>
            <td className='px-4 py-3'>
                {loan?.guarantorIdType} : <span className='font-medium'>{loan?.guarantorIdNumber}</span>
            </td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Relationship with Guarantor</td>
            <td className='px-4 py-3 capitalize'>{loan?.guarantorRelationship}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Guarantor's Address</td>
            <td className='px-4 py-3 capitalize'>{loan?.guarantorAddress}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Guarantor digital address</td>
            <td className='px-4 py-3 capitalize'>{loan?.guarantorDigitalAddress}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Guarantor's date of birth</td>
            <td className='px-4 py-3 capitalize'>{dateToHumanFormat(loan?.guarantorDob)}</td>
        </tr>

        {/* other information */}
        <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
            <td className='px-4 py-3'></td>
            <td className='px-4 py-3'></td>
        </tr>
        <tr colSpan="2" className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td colSpan="2" className='px-4 py-3 font-bold'>Next of Kin Information</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Next of Kin</td>
            <td className='px-4 py-3'>{loan?.client?.nextOfKin}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Next of Kin Phone Number</td>
            <td className='px-4 py-3'>{loan?.client?.nextOfKinPhone}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Address of Next of Kin</td>
            <td className='px-4 py-3'>{loan?.client?.nextOfKinAddress}</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Relationship to Next of Kin</td>
            <td className='px-4 py-3'>{loan?.client?.nextOfKinRelationship}</td>
        </tr>


        {/* other information */}
        <tr colSpan="2" className='px-4 py-6 border-b text-gray-500'>
            <td className='px-4 py-3'></td>
            <td className='px-4 py-3'></td>
        </tr>
        <tr colSpan="2" className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td colSpan="2" className='px-4 py-3 font-bold'>Other Information</td>
        </tr>
        <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
            <td className='px-4 py-3'>Loan Relationship Manager</td>
            <td className='px-4 py-3'>{loan?.relManager}</td>
        </tr>
        </>
    )
}

export default Info